// import one from '../assets/svg/projects/one.svg'
// import two from '../assets/svg/projects/two.svg'
// import three from '../assets/svg/projects/three.svg'
// import four from '../assets/svg/projects/four.svg'
// import five from '../assets/svg/projects/five.svg'
// import six from '../assets/svg/projects/six.svg'
// import seven from '../assets/svg/projects/seven.svg'
// import eight from '../assets/svg/projects/eight.svg'

export const projectsData = [
  {
    id: 1,
    projectName: "History Through Movies",
    projectDesc:
      "This project seeks to explore whether the pedagogy of history can be improved with the visual media, particularly Cinema. The Website is intended to show movies that fit the bill. I have a fullstack role in this having built all of it.",
    tags: ["Django", "HTML", "CSS", "Javascript"],
    code: "https://github.com/sreerag-rajan/historythroughmovies",
    demo: "https://historythroughmovies.pythonanywhere.com/",
    image:
      "https://github.com/sreerag-rajan/historythroughmovies/raw/master/htclandingpage.png",
  },
  {
    id: 2,
    projectName: "Sportsjam.in Clone",
    projectDesc:
      "This is a clone of an e-commerce website sportsjam.in. My contribution to this project was the product catalogue page and its features, like sorting and filtering. The rest of the project was built by various members of the group",
    tags: ["HTML", "CSS", "Javascript"],
    code: "https://github.com/sreerag-rajan/sportsjam-clone",
    demo: "https://practical-meninsky-36f305.netlify.app/",
    image:
      "https://camo.githubusercontent.com/f9fbe30b577cff2fbf09af53fb3c1f12fcc10a771bf9459ed5af10bffab9e38b/68747470733a2f2f6d69726f2e6d656469756d2e636f6d2f6d61782f3837352f312a525f5f63646e384c316759474b3154647171797562672e706e67",
  },
  {
    id: 3,
    projectName: "Educative Clone",
    projectDesc:
      "This project is the clone of Educative.io. Built as part of Project week, my contributions include the home page(both with user and without user), course information page and checkout page. The rest of the pages and features were built by other members of my team",
    tags: ["React", "Material UI", "Express", "MongoDB", "NodeJS"],
    code: "https://github.com/sreerag-rajan/educative-clone",
    demo: "https://educative-clone.vercel.app/",
    image:
      "https://user-images.githubusercontent.com/85482640/161571651-35ab8e4e-dc57-4834-bafc-9485766f5b8a.png",
  },
  {
    id: 4,
    projectName: "Reliance Digital Clone - Backend Integration",
    projectDesc:
      "This is the clone of reliancedigital.in. Features I worked on the Product Catalogue pages and also on some minor parts on other pages as well",
    tags: ["EJS", "Express", "MongoDB", "Node.js"],
    code: "https://github.com/sreerag-rajan/Reliance-Digital-Clone-U4-",
    demo: "https://reliance-digital-clone.herokuapp.com/",
    image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png",
  },
  {
    id: 5,
    projectName: "Reliance Digital Clone - Frontend",
    projectDesc:
      "This project is the clone of Reliance Digital built as a part of a project week. It simulates the functionality of an e-commerce website from landing page to checkout. My contribution was the product catalog page arriving from the navbar, as well as leading the team.",
    tags: ["HTML", "CSS", "Javascript"],
    code: "https://github.com/iamrituyadav/Reliance_digital",
    demo: "https://reliance-digital-clone.herokuapp.com/",
    image: "https://miro.medium.com/max/875/1*qRNfRbuHCDC1ct0chLZoWA.png",
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
