import resume from "../assets/pdf/resume.pdf";

export const headerData = {
  name: "Eden Konde",
  title: "Full Stack Developer",
  desciption:
    "Things will not always happen according to our planning as we will never be able to measure every possible event in the process, so it is essential to be patient and reinvent yourself as many times as necessary to move forward.",
  image:
    "https://media.licdn.com/dms/image/D4D03AQH2m99NeDcwig/profile-displayphoto-shrink_400_400/0/1709093627514?e=1716422400&v=beta&t=f3FKvYtCuY5NQsSrgEItizebd4asLd5nMe-jRA5hKKo",
  resumePdf: resume,
};
