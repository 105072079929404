export const experienceData = [
  {
    id: 1,
    company: " LADECORA",
    jobtitle: " Full stack developer",
    startYear: "2022",
    endYear: "2023",
  },
  {
    id: 2,
    company: " TECHMAIL",
    jobtitle: " Development Assistant ",
    startYear: "2017",
    endYear: "2022",
  },
];
