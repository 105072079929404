import totiCertification from "../assets/png/toti-certificatin.png";
export const achievementData = {
  bio: "We must take advantage of the opportunities that cross our path that bring us closer and closer to our goal.",
  achievements: [
    {
      id: 1,
      title: "2020 Hiring Coders - Full Stack Development ",
      details: "A Full Stack course from Gama Academy",
      date: "Aug 25, 2020",
      field: "Coding",
      image:
        "https://media.licdn.com/dms/image/C4E22AQGTunLrdPsM1Q/feedshare-shrink_800/0/1598458191444?e=1713398400&v=beta&t=A4vVAxOo0Tqro4OHZNy1jt-LDuDnA5kph_Fj_7gdlu8",
    },
    {
      id: 2,
      title: "Toti training program - Back-End",
      details: "A Back-End course from Toti",
      date: "Sept 10, 2021",
      field: "Coding",
      image: totiCertification,
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
